'use client';

import {useMemo, useState} from 'react';
import {useSubscription} from '~/shared/data/subscription';
import {useSupabase} from '~/shared/hooks/use-supabase';
import {IconButton} from '@job-ish/ui/components';
import {IconLayoutSidebarLeftExpand} from '@tabler/icons-react';
import clsx from 'clsx';
import {usePathname} from 'next/navigation';

import type {PropsWithChildren} from 'react';
import {Header} from './header';
import {Sidebar} from './sidebar';

type LayoutShellProps = PropsWithChildren;

export const LayoutShell = ({children}: LayoutShellProps) => {
	const [sidebarOpen, setSidebarOpen] = useState<boolean>();
	const pathname = usePathname();
	const {data: subscription} = useSubscription();
	const {user} = useSupabase();

	const shouldShowSidebar = useMemo(
		() => pathname !== '/subscribe' && user && subscription?.status === 'active',
		[pathname, subscription?.status, user],
	);

	return (
		<>
			{shouldShowSidebar && <Sidebar onOpenChange={setSidebarOpen} open={sidebarOpen} />}
			<div className="flex h-full max-h-dvh min-h-full w-dvw grow flex-col items-stretch overflow-hidden bg-mauve1">
				<div className="flex">
					{!sidebarOpen && shouldShowSidebar && (
						<div className="flex h-14 w-fit flex-col items-center border-b border-mauve6 p-2">
							<IconButton
								aria-label="Open Sidebar"
								className="flex md:hidden"
								icon={IconLayoutSidebarLeftExpand}
								intent="ghost"
								onPress={() => setSidebarOpen(true)}
							/>
						</div>
					)}
					{user && (
						<>
							<div className="hidden w-full md:flex">
								<Header sidebarOpen={sidebarOpen} />
							</div>
							<div className={clsx(sidebarOpen ? 'ml-14' : '', 'flex grow overflow-hidden md:hidden')}>
								<Header sidebarOpen={false} />
							</div>
						</>
					)}
				</div>
				<div
					className={clsx(
						'flex grow flex-col overflow-hidden px-4 [@media(min-width:1800px)]:container [@media(min-width:1800px)]:mx-auto',
						user && 'py-2',
					)}
				>
					{children}
				</div>
			</div>
		</>
	);
};
